<template>
  <products :non_editable="true" :customer-id="customerId" />
</template>

<script>
import Products from "@/views/products/ProductsList";

export default {
  name: "ResellerProducts",
  components: {
    Products,
  },
  props: {
    customerId: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
